main {
    display: block;

    background: lightblue;

    padding: 2em;
}

article {
    display: block;
    background: white;

    height: 80%;
    padding: 1em;
    margin-bottom: 1em;

    box-sizing: border-box;
}

p.robo-text { opacity: 0; }
p.robo-text-0 { animation: fadeIn 0.4s ease 0.3s forwards; }
p.robo-text-1 { animation: fadeIn 0.4s ease 1.3s forwards; }
p.robo-text-2 { animation: fadeIn 0.4s ease 2.3s forwards; }
p.robo-text-3 { animation: fadeIn 0.4s ease 3.3s forwards; }
p.robo-text-4 { animation: fadeIn 0.4s ease 4.3s forwards; }
p.robo-text-5 { animation: fadeIn 0.4s ease 5.3s forwards; }
p.robo-text-6 { animation: fadeIn 0.4s ease 6.3s forwards; }
p.robo-text-7 { animation: fadeIn 0.4s ease 7.3s forwards; }
p.robo-text-8 { animation: fadeIn 0.4s ease 8.3s forwards; }
p.robo-text-9 { animation: fadeIn 0.4s ease 9.3s forwards; }

p.robo-text:last-child {
    font-weight: bold;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
