@font-face {
    font-family: 'Roboto';
    src: url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
}

body {
    background-color: gray;

    font-family: 'Roboto', sans-serif;
}

body > * {
    background: white;
    padding: 1.4em 1em 1em 1em;
}